import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@common/Footer';

import AboutSec from '@sections/About';


const About = () => (
  <Layout>
    <Navbar />
    <AboutSec />
    <Footer />
  </Layout>
);

export default About;
