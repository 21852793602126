import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        cornwall: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "cornwall-1" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        lydia_workshop: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "lydia_workshop" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        rockface: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "rockface" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        seaweed: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "seaweed_barnacles" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        seagrass: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "seagrass" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about" >
        <Container>
        <Banner>
              <Img fluid={data.cornwall.childImageSharp.fluid} />
        </Banner>
          
          <Grid>
          <Art>
              <Img fluid={data.lydia_workshop.childImageSharp.fluid} alt='cornwall panorama'/>
            </Art>
            <Text>
              <p>
              Growing up in Cornwall has shaped and inspired the jewellery that I create. I draw inspiration from natural forms found on the coastline and in the sea itself. I spend as much time as possible scouring beaches finding treasures and studying the intricate formations of barnacles and rocks. This gives my jewellery a natural and organic feel.
              </p>
            </Text>
          </Grid>
          <Grid inverse>
            
            <Text>
              <p>I mainly work with silver, using lost wax and granulation techniques. The beauty of this

is all of my pieces are unique,

no two will be exactly the same. 

All of my creations are brought to life in my studio in Bristol.</p>
            </Text>
            <Art>
              <Img fluid={data.rockface.childImageSharp.fluid} alt='rockface'/>
            </Art>
          </Grid>
          <Grid>
          <Art>
              <Img fluid={data.seaweed.childImageSharp.fluid} alt='seaweed'/>
            </Art>
            <Art>
              <Img fluid={data.seagrass.childImageSharp.fluid} alt='seagrass'/>
            </Art>
          </Grid>
          
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 50px 50px;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 2fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Text} {
          order: 1;
        }
    `}
`;

const Text = styled.div`
  margin: 0;
  max-width: 380px;
  width: 100%;
  text-align: center;

  p{
    color: #211E26;
    ${props => props.theme.font_size.small};
  }
`;

const Banner = styled.div`
padding: 0 50px;
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
